body {
  font-family: "poppins", sans-serif;
}

.flex-center {
  @apply flex items-center justify-center;
}

.blue-shadow {
  box-shadow: -5px -110px 140px -65px #05d7d763;
}

.mobile_carsouel {
  & div {
    @apply transform transition-all  scale-75  opacity-40 py-4;
  }

  &-active {
    & div {
      @apply transform scale-110 transition-all  opacity-100;
    }
  }
}

.pagination-btn,
.pagination-dissabled {
  background-color: #c6d4e8;
}

.pagination-active {
  @apply bg-primary-blue;
}
iframe.persona-widget__iframe {
  width: 100%;
  height: 600px;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #23426e;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 0.8s linear infinite; /* Safari */
  animation: spin 0.8s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.infoBox {
  overflow: visible !important ;
}

.dotloader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dotloading {
  width: 12px;
  height: 12px;
  background-color: #23426e;
  border-radius: 50%;
  margin: 0 5px;
  animation: dot-flashing 1.5s infinite ease-in-out;
}
.dotloading:nth-child(2) {
  animation-delay: 0.3s;
}
.dotloading:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes dot-flashing {
  0%, 100% {
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
}
