@tailwind base;
@tailwind components;
@tailwind utilities;
@import './front';
@import './utils';

#root {
    // max-width: 1820px;
    @apply mx-auto;
}
.placeholder-cahnge {
    &::placeholder {
        color: #191e3b;
    }
}
.image-shadow {
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2);
}

.marging-none {
    margin-right: calc(0rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
@media screen and (min-width: 640px) {
    .expire-label {
        transform: rotate(-45deg) !important;
        top: 14px !important;
        left: -26px !important;
    }
}
@media screen and (max-width: 639px) {
    .rdrMonth {
        width: 100% !important;
    }
    .expire-label {
        transform: rotate(-45deg) !important;
        top: 14px !important;
        left: -26px !important;
    }

    .rdrMonthAndYearPickers {
        select {
            padding: 6px 22px 6px 10px !important;
        }
    }
    .rdrNextPrevButton {
        margin: 0 0.233em;
    }
    .temp-class {
        width: fit-content !important;
    }
}
@media screen and (min-width: 640px) and (max-width: 1280px) {
    .temp-class {
        width: fit-content !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
    .rdrMonth {
        width: 100% !important;
    }

    .rdrMonthAndYearPickers {
        select {
            padding: 6px 22px 6px 10px !important;
        }
    }
    .rdrNextPrevButton {
        margin: 0 0.233em;
    }
}
@media screen and (max-width: 767px) {
    .navbard-shadow {
        box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.25);
    }
}

.expire-label {
    position: absolute;
    width: 100px;
    text-align: center;
    top: 22%;
    left: -4%;
    transform: translate(-12%, -3px) rotate(-45deg);
    font-size: 12px;
}

.maintenance_root {
    background-image: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.5) 102%),
        url('../assets/maintenance_mode.png');
    height: 100vh;
    background-repeat: no-repeat;
    padding: 83px 130px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background-size: cover;
    background-position: center;
    font-weight: 700;
    color: white;
}
.site-logo {
    display: flex;
    align-items: center;
    margin-top: 22px;
    gap: 52px;
}
.facbook-btn {
    padding: 15px;
    border-radius: 25px;
}
.content {
    margin-top: 60px;
    font-size: 27px;
    line-height: 36px;
    font-family: 'Roboto', sans-serif;
}
.container {
    width: 837px;
}
.content p:nth-child(2) {
    margin-top: 30px;
}
.content p:nth-child(3) {
    margin-top: 30px;
}
.content p:nth-child(4) {
    margin-top: 30px;
}
.maintenance_root h1 {
    font-size: 68px;
    line-height: 75px;
    font-family: 'Lexend Deca', sans-serif;
}
.span-coloe {
    color: #94ba33;
}
.chat-spacing {
    margin-top: 10px;
}
@media screen and (max-width: 767px) {
    .maintenance_root {
        padding: 15px;
        justify-content: center;
    }
    .site-logo {
        flex-direction: column;
        gap: 25px;
        align-items: flex-start;
    }
    .content {
        margin-top: 30px;
        font-size: 18px;
        line-height: 24px;
    }
    .container {
        width: 100%;
    }
    .content p:nth-child(2) {
        margin-top: 10px;
    }
    .content p:nth-child(3) {
        margin-top: 10px;
    }
    .content p:nth-child(4) {
        margin-top: 10px;
    }
    .maintenance_root h1 {
        font-size: 28px;
        line-height: 40px;
        font-family: 'Lexend Deca', sans-serif;
    }
    .span-coloe {
        color: #94ba33;
        display: inline-block;
        width: 100%;
    }
}
@media screen and (min-width: 767px) and (max-width: 1023px) {
    .maintenance_root {
        padding: 40px;
        justify-content: center;
    }
    .site-logo {
        gap: 30px;
    }
    .content {
        margin-top: 30px;
        font-size: 18px;
        line-height: 24px;
    }
    .container {
        width: 100%;
    }
    .content p:nth-child(2) {
        margin-top: 20px;
    }
    .content p:nth-child(3) {
        margin-top: 20px;
    }
    .content p:nth-child(4) {
        margin-top: 20px;
    }
    .maintenance_root h1 {
        font-size: 42px;
        line-height: 52px;
        font-family: 'Lexend Deca', sans-serif;
    }
    .span-coloe {
        color: #94ba33;
        display: inline-block;
        width: 100%;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .maintenance_root {
        padding: 40px;
        justify-content: center;
    }
}
@media screen and (min-width: 1630px) {
    .image-cover {
        background-size: 100% 200%;
    }
}



/* CSS for Diagonal Split */
.clip-half-start {
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }
  
  .clip-half-end {
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
  }
  